<template>
    <div>
        <v-container>
             <v-form>
                 <v-autocomplete
                    v-model="main_form.state_id"
                    :items="states"
                    :search-input.sync="state_search"
                    item-value="id"
                    item-text="name"
                    cache-items
                    :rules="[rules.required]"
                    placeholder="eg Lagos"
                    label="State"
                    clearable
                    outlined
                ></v-autocomplete>
            <v-autocomplete
                v-model="main_form.lga_id"
                :items="lgas"
                :search-input.sync="lga_search"
                item-value="id"
                item-text="name"
                cache-items
                :rules="[rules.required]"
                placeholder="eg Surulere"
                label="Locality"
                clearable
                outlined
        ></v-autocomplete>
             <div class="form-group" >
                <label class="form-label">Property Type</label>
                <span id="class-id" style="padding:0">
                <v-overflow-btn clearable  outlined open-on-clear
                                v-model="main_form.property_class_id"
                                :items="property_class"
                                item-text="name"
                                item-value="id"
                                label="select"
                                target="#class"
                                :rules="[rules.required]"
                ></v-overflow-btn>
            </span>
            </div>
            <div class="form-group">
                <label class="form-label">Property Class</label>
                <span id="#unit_type_id"  style="padding:0">
                    <v-overflow-btn v-model="main_form.property_type"
                                    clearable outlined open-on-clear
                                    :items="property_type"
                                    item-value="name"
                                    item-text="name"
                                    label="select"
                                    name="home_type"
                                    :loading="apiloading"
                                    target="unit_type_id-type-id"
                                    :rules="[rules.required]"
                    ></v-overflow-btn>
                        </span>
            </div>
            <div class="form-group" >
                <label class="form-label">Bedrooms</label>
                <v-overflow-btn v-model="main_form.bedrooms"
                                clearable outlined open-on-clear
                                :items="[0,1,2,3,4,5,'6+']"
                                label="select"
                                :rules="[rules.required]"
                ></v-overflow-btn>

            </div>
            <div class="form-group" >
                <label class="form-label">Bathrooms</label>
                <v-overflow-btn v-model="main_form.bathrooms"
                                clearable outlined open-on-clear
                                :items="[0,1,2,3,4,5,'6+']"
                                label="select"
                                :rules="[rules.required]"
                ></v-overflow-btn>
            </div>
            <div class="form-group" >
                <label class="form-label">Garage</label>
                <v-overflow-btn v-model="main_form.garages"
                                clearable outlined open-on-clear
                                :items="[0,1,2,3,4,5,'6+']"
                                label="select"
                                :rules="[rules.required]"
                ></v-overflow-btn>
            </div>
            
                              <v-btn style="color:#fff !important;" height="48" type="submit" :loading="apiloading" :disabled="apiloading" block color="secondary" >Submit Request</v-btn>
        </v-form>
        </v-container>
       
            
                 
    
    </div>
</template>
<script>
import { loadingMixin } from '../../../mixins/Loading';
export default {
    inject:["Bus"],
    mixins:[loadingMixin],
    data() {
        return {
            pageTitle:"Property Request",
             property_type:[],
             lga_search:'',
             state_search:'',
             main_form:{
                 state_id:'',
                 lga_id:'',
                 bedrooms:'',
                 bathrooms:'',
                 garages:'',
                 price:'',
                 property_class_id:'',
                 property_type:''
             },
              rules: {
            required: value => !!value || "Required.",
                phone:value=>{
            const regre = /[0-9]|\./
                return regre.test(value) || "Invalid number";
            },
        },
        }
    },
    watch: {
          'main_form.property_class_id'(newValue, oldValue) {
            this.getPropertyTypeByClassId(newValue)
        },
        'main_form.state_id'(newValue, oldValue) {
            this.getLgaId(newValue)
        },
    },
    computed: {
        states(){
                return this.$store.state.general_module.all_states;
            },
        property_class() {
                return this.$store.state.general_module.all_property_class
            },
    },
    methods: {
         getPropertyApiData() {
             this.$store.dispatch("getAmenitiesAction");
             this.$store.dispatch("getAllPropertyStatusAction");
              if(this.form_type !== 'New'){
                this.$store.dispatch("viewPropertyDetailAction",this.form_type);
              }
         },
        getLgaId(id){
            this.$store.dispatch("getAllStateLGA",id).then((res)=>{
                this.lgas = res;
            })
        },
         getPropertyTypeByClassId(id) {
            this.$store.dispatch("getAllPropertyTypeAction",id).then((res)=>{
                this.property_type = res;
            })
        },
    },
    created(){
        this.getPropertyApiData();
    }
}
</script>